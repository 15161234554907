




































































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Status from '@/api/models/Status'
import Statuses from '@/api/models/Statuses'
import { RootStateMutations } from '@/store/mutations'
import Connection from '@/api/models/Connection'
import { getBrandName } from '@/shared/components/helper'

interface CurrentStatuses {
    newStatus: Status;
    approveStatus: Status;
    completeStatus: Status;
    cancelStatus: Status;
    notAccepted: Status;
    arbitration: Status;
    delivering: Status;
    driverPickup: Status;
}

@Component({})
export default class StatusesMapping extends Vue {
    @Prop({ type: Object, default: () => { return {} } })
    readonly statusesProp!: Statuses

    @Prop({ type: Boolean, default: () => { return false } })
    readonly serverErrors!: boolean

    @Watch('serverErrors')
    onServerErrorsChanged () {
        if (this.serverErrors) {
            this.validate()
        }
    }

    @Getter('connection')
    connection!: Connection

    get fullBrand (): string {
        return getBrandName(this.connection)
    }

    private currentStatuses: CurrentStatuses = {
        newStatus: StatusesMapping.emptyStatus,
        approveStatus: StatusesMapping.emptyStatus,
        completeStatus: StatusesMapping.emptyStatus,
        cancelStatus: StatusesMapping.emptyStatus,
        notAccepted: StatusesMapping.emptyStatus,
        arbitration: StatusesMapping.emptyStatus,
        delivering: StatusesMapping.emptyStatus,
        driverPickup: StatusesMapping.emptyStatus
    }

    private errors: { [key: string]: string } = {}
    private init = false

    @Getter('installer/getStatuses')
    private readonly statuses!: Array<Status>

    @Action('installer/loadStatuses')
    loadStatuses!: () => Promise<void>;

    async created () {
        this.$store.commit(RootStateMutations.setLoading, true)

        try {
            await this.loadStatuses()

            const statusesProp = Object.entries(this.statusesProp)
            const entries: Array<Array<string | Status>> = []
            for (const [key, value] of statusesProp) {
                const findStatus = this.statuses.find((status: Status) => {
                    return status.code === value
                })

                if (findStatus) {
                    entries.push([key, findStatus])
                }
            }

            this.currentStatuses = Object.fromEntries(entries) as CurrentStatuses
            this.init = true
        } finally {
            this.$store.commit(RootStateMutations.setLoading, false)
        }
    }

    static get emptyStatus (): Status {
        return { name: '', code: '', active: false }
    }

    private updateEvent (): void {
        this.errors = {}
        this.validate()
        if (!Object.keys(this.errors).length) {
            const statuses: Statuses = {
                newStatus: this.currentStatuses.newStatus?.code ?? '',
                approveStatus: this.currentStatuses.approveStatus?.code ?? '',
                completeStatus: this.currentStatuses.completeStatus?.code ?? '',
                cancelStatus: this.currentStatuses.cancelStatus?.code ?? '',
                notAccepted: this.currentStatuses.notAccepted?.code,
                arbitration: this.currentStatuses.arbitration?.code,
                delivering: this.currentStatuses.delivering?.code,
                driverPickup: this.currentStatuses.driverPickup?.code
            }

            this.$emit('update', statuses)
        }
    }

    public validate (): boolean {
        const errors: { [key: string]: string } = {}

        if (!this.currentStatuses.newStatus) {
            errors.newStatus = this.$t('settings.statuses.error.notEmpty').toString()
        }

        if (!this.currentStatuses.approveStatus) {
            errors.approveStatus = this.$t('settings.statuses.error.notEmpty').toString()
        }

        if (!this.currentStatuses.cancelStatus) {
            errors.cancelStatus = this.$t('settings.statuses.error.notEmpty').toString()
        }

        if (!this.currentStatuses.completeStatus) {
            errors.completeStatus = this.$t('settings.statuses.error.notEmpty').toString()
        }

        this.errors = Object.assign({}, errors)

        return !(Object.keys(errors).length > 0)
    }
}
