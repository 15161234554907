






















import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import StatusesMapping from '@/shared/components/StatusesMapping.vue'
import BaseLayout from '@/shared/components/BaseLayout.vue'
import Account from '@/api/models/Account'
import AccountService from '@/api/services/AccountService'
import StatusesDto from '@/api/models/Statuses'
import AlertType from '@/api/models/AlertType'

const accountService = new AccountService()

@Component({
    components: {
        BaseLayout,
        StatusesMapping
    }
})
export default class Statuses extends Vue {
    @Getter('getCurrentAccount')
    account!: Account

    private init = false
    private alert: AlertType = {
        alertType: 'error',
        alertMessage: ''
    }

    private emptyFields = false

    created (): void {
        this.init = true
    }

    get newStatus (): string {
        return this.account && this.account.statuses ? this.account.statuses.newStatus : ''
    }

    get approveStatus (): string {
        return this.account && this.account.statuses ? this.account.statuses.approveStatus : ''
    }

    get completeStatus (): string {
        return this.account && this.account.statuses ? this.account.statuses.completeStatus : ''
    }

    get cancelStatus (): string {
        return this.account && this.account.statuses ? this.account.statuses.cancelStatus : ''
    }

    get statuses (): StatusesDto {
        return {
            newStatus: this.account.statuses?.newStatus ?? '',
            approveStatus: this.account.statuses?.approveStatus ?? '',
            completeStatus: this.account.statuses?.completeStatus ?? '',
            cancelStatus: this.account.statuses?.cancelStatus ?? '',
            notAccepted: this.account.statuses?.notAccepted,
            arbitration: this.account.statuses?.arbitration,
            delivering: this.account.statuses?.delivering,
            driverPickup: this.account.statuses?.driverPickup
        }
    }

    async updateStatuses (statuses: StatusesDto): Promise<void> {
        this.closeAlert()
        this.emptyFields = false
        if (this.account.id) {
            try {
                await accountService.updateStatuses(this.account.id, statuses)
                this.showAlert(this.$t('response.successful').toString(), 'success')
            } catch (err) {
                let message = ''
                if (
                    Array.isArray(err.response.data.details) &&
                    err.response.data.details.length > 0
                ) {
                    message = err.response.data.details[0]
                    if (message === 'empty_main_status') {
                        this.emptyFields = true
                        return
                    }
                } else {
                    message = err.response.data.message ?? err
                }
                this.showAlert(message, 'error')
            }
        }
    }

    private showAlert (message: string, type = 'error'): void {
        this.alert.alertType = type
        this.alert.alertMessage = message
    }

    private closeAlert (): void {
        this.alert.alertMessage = ''
    }
}
